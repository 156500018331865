.active-filter {
    text-decoration: underline;
    color: #3C9CE6;
}

.listings {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 4rem 2rem;

    @media (max-width: 1350px) {
        grid-template-columns: repeat(3, 1fr);
    }

    .title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 24px;
        font-size: 18px;
        font-weight: 700;
    }

    .description {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 24px;
        height: 48px;
    }
}

.listing-img {
    font-size: 14px;
    display: flex;
    align-items: flex-end;
    justify-content: end;
    height: auto;

    a,
    img {
        height: auto;
        width: 100%;
        object-fit: cover;
        border-radius: .25rem;
    }
}

.categories-subpills {
    .nav-link {
        font-size: 12px;
    }
}

.categories-pills {
    .nav-link {
        font-size: 10px;
    }

    @media (min-width: 340px) {
        .nav-link {
            font-size: 11px;
        }
    }
    @media (min-width: 400px) {
        .nav-link {
            font-size: 14px;
        }
    }
}


.title {
    letter-spacing: .5px;
}

a.fresh {
    height: auto;
    width: auto;
    left: 0;
    top: 0;
    border-radius: 4px 0 4px 0;
    cursor: default;
    height: 37px;
}

.no-reserve,
.fresh {
    display: flex;
    align-items: center;
    height: 25px;

    span {
        font-size: 14px;
    }
}

.black-div {
    background: rgba(0, 0, 0, 0.6);
    z-index: 10;
    right: 0;
    top: 0;
    border-radius: 0 4px 0 4px;
}

a.share-auction,
a.comments {
    height: auto;
}

.amount-timer {
    border-radius: 35px 0 0 35px;
    bottom: 18px;
    right: 0;
    padding: 0;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1);
    overflow: hidden;
    position: relative;
    font-size: 15px;

    .fa-clock {
        padding-top: 1.5px;
    }

    .bid {
        width: 140px;
        padding: 6px 0;
    }

    .time-div {
        width: 140px;
        padding: 6px 0;
    }
}

.listings-categories a {
    color: gray;
}

.listings-categories .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: transparent;
    text-decoration: underline;
    color: #3C9CE6;
}

// responsive

// tablet

@media(max-width: 1199px) {
    .listings {
        grid-template-columns: 1fr 1fr;
    }
}

// phone

@media(max-width: 767px) {
    .container {
        padding: 0;
        margin: 0;
        max-width: 100%;
    }

    .listings {
        grid-template-columns: 1fr;
    }

    .listings-categories {
        font-size: 13px;
        display: flex;
        justify-content: center;
    }

    .listing-img {
        min-width: 100vw;

        a,
        img {
            border-radius: 0;
        }
    }

    .description {
        -webkit-line-clamp: 3;
        height: 72px;
    }

}

